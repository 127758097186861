import { container, title } from "assets/jss/material-kit-react.js";

const landingPageStyle = {
  mapDiscription:{
    ...container,
    zIndex: "12",
    // color: "#FFFFFF",
  },
  container: {
    zIndex: "12",
    // color: "#FFFFFF",
    ...container,
    padding: "12px",
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "72px",
    minHeight: "40px",
    color: "#FFFFFF",
    fontSize: "32px",
    textDecoration: "none",
    "@media (max-width: 400px)": {
      fontSize: "24px",
    },
  },
  discription: {
    color: "#FFF",
    fontSize: "1.2rem",
    background: "rgba(0,0,0,0.1)",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    margin: "10px auto 0",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "0px 0px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
};

export default landingPageStyle;
